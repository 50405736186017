import React from 'react'
import Container from '../components/container'
import LandingMapAndContact from '../components/landing/landing-map-contact'
import LandingPortfolio from '../components/landing/landing-portfolio'
import LandingQuestionsAndProjects from '../components/landing/landing-questions-projects'
import PageTitle from '../components/page-title'
import SEO from '../components/seo'

const OurWorksPage = ({ animate }) => {
  return (
    <>
      <SEO title="Portfolio" description="Check out our portfolio" />
      <PageTitle title="Portfolio" animate={animate} />
      <Container mb="10vmax">
        <LandingPortfolio animate={animate} withFilters limit={6} />
      </Container>
      <LandingQuestionsAndProjects question={false} />
      <LandingMapAndContact />
    </>
  )
}

export default OurWorksPage
